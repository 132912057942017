<template>
	<div>
		<div class="org-section__partners org-section__partners--slider max-w-500">
			<swiper v-if="organisations.length" :options="swiperOption()">
				<swiper-slide
					v-for="(organisation, index) in organisations"
					:key="`slider-${index}`"
				>
					<div
						class="org-section__partners__item"
						@click="setOrganisation(organisation)"
					>
						<div
							v-if="organisation.image"
							key="organisation-image"
							class="org-section__partners__item__image"
						>
							<img :src="organisation.image" alt="" />
						</div>
						<p v-else key="no-organisation-image">
							{{ organisation.name }}
						</p>
					</div>
				</swiper-slide>
				<img
					slot="button-prev"
					class="swiper-button-prev"
					src="@/assets/imgs/icons/triangle.svg"
					alt=""
				/>
				<img
					slot="button-next"
					src="@/assets/imgs/icons/triangle.svg"
					class="swiper-button-next icon-rotation"
					alt=""
				/>
			</swiper>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { FETCH_MY_ORGANISATION } from '@/store/actions.type';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
	components: {
		Swiper,
		SwiperSlide
	},
	computed: {
		...mapState({
			organisation: state => state.organisation.organisation,
			organisations: state => state.organisation.organisations
		})
	},
	methods: {
		setOrganisation(organisation) {
			this.$store.dispatch(FETCH_MY_ORGANISATION, organisation);
		},
		swiperOption() {
			return {
				slidesPerView: this.organisations.length > 3 ? 3 : this.organisations.length,
				spaceBetween: 0,
				loop: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				breakpoints: {
					1024: {
						slidesPerView:
							this.organisations.length > 3 ? 3 : this.organisations.length
					},
					640: {
						slidesPerView:
							this.organisations.length > 2 ? 2 : this.organisations.length
					},
					320: {
						slidesPerView: 1
					}
				}
			};
		}
	}
};
</script>
