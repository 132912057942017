<template>
	<div class="tab-section__content__card tab-section__content__card--visuals">
		<form @submit.prevent="submit" v-if="countries.length">
			<div class="organisation-profile">
				<div class="organisation-profile__row">
					<div class="details details--manage">
						<VDefaultField
							label="Organisation"
							:inline="true"
							:error="errors.get('name')"
						>
							<template #field>
								<VInput
									v-model="form.name"
									type="text"
									placeholder="Koko Networks"
									:error="errors.get('name')"
									@input="errors.clear('name')"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="Org email"
							:inline="true"
							:error="errors.get('email')"
						>
							<template #field>
								<VInput
									v-model="form.email"
									type="email"
									placeholder="info@koko.org"
									:error="errors.get('email')"
									@input="errors.clear('email')"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="Org website"
							:inline="true"
							:error="errors.get('website')"
						>
							<template #field>
								<VInput
									v-model="form.website"
									type="url"
									placeholder="https://www.koko.org"
									:error="errors.get('website')"
									@input="errors.clear('website')"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="Address"
							:inline="true"
							:error="errors.get('lat') || errors.get('lon') ? 'Please select address.' : null"
						>
							<template #field>
								<google-autocomplete
									ref="address"
									id="map"
									classname="form__input"
									placeholder="Start typing"
									:fields="['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity']"
									v-on:placechanged="getAddressData"
									types="['establishment','address']"
									@input="errors.clear('lat'); errors.clear('lon')"
								>
								</google-autocomplete>
								<iframe
										v-if="form.address"
										width="400"
										height="300"
										style="border:0"
										loading="lazy"
										allowfullscreen
										:src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyCbP-eg17mbHxIG1PbJ9yIhZ6JtLsxUImw&q=${encodeURI(form.address)}`">
								</iframe>
							</template>
						</VDefaultField>

						<!--<VDefaultField
								label="Location"
								:inline="true"
								:error="errors.get('location')"
						>
							<template #field>
								<CountriesMultiselect
										v-model="form.location"
										classes="form__multiselect&#45;&#45;beige full"
										:multiple="false"
										:closeOnSelect="true"
										:queryParams="{ global: 1 }"
										@input="errors.clear('location')"
								/>
							</template>
						</VDefaultField>-->
					</div>
					<div class="logo">
						<div class="avatar-content">
							<div>
								<croppa
									v-bind="
										form.initialPhoto
											? {
													'initial-image':
														form.initialPhoto
											  }
											: {}
									"
									:key="
										`organisation-image-${
											organisation
												? organisation.id
												: 'new'
										}`
									"
									v-model="form.profilePhoto"
									:disable-click-to-choose="true"
									:show-remove-button="false"
									accept="image/*"
									ref="croppa"
								></croppa>
							</div>
							<div class="avatar-action">
								<div>
									<img
										src="@/assets/imgs/icons/icon-edit.svg"
										alt=""
									/>
									<a
										@click="form.profilePhoto.chooseFile()"
										href="#"
										class="link link--small"
									>
										Change profile pic
									</a>
								</div>
								<div>
									<img
										src="@/assets/imgs/icons/icon-delete.svg"
										alt=""
									/>
									<a
										href="#"
										class="link link--small"
										@click="form.profilePhoto.remove()"
									>
										Delete profile pic
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--<VDefaultField
					label="Address"
					:inline="true"
					:error="errors.get('address')"
				>
					<template #field>
						<VTextarea
							v-model="form.address"
							placeholder="Address line 1"
							:error="errors.get('address')"
							@input="errors.clear('address')"
						/>
					</template>
				</VDefaultField>-->
				<VDefaultField
					label="Overview"
					:inline="true"
					:error="errors.get('overview')"
				>
					<template #field>
						<VTextarea
							v-model="form.overview"
							placeholder="Organisation overview"
							:error="errors.get('overview')"
							@input="errors.clear('overview')"
						/>
					</template>
				</VDefaultField>
				<div class="organisation-profile__row">
					<div class="details details--manage">
						<VDefaultField
							label="Specialisms"
							:inline="true"
							:error="errors.get('specialisms')"
						>
							<template #field>
								<SpecialismsMultiselect
									v-model="form.specialisms"
									class="form__multiselect--beige full"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="Twitter url"
							:inline="true"
							:error="errors.get('socials.twitter')"
						>
							<template #field>
								<VInput
									v-model="form.socials.twitter"
									type="url"
									placeholder="@koko"
									:error="errors.get('socials.twitter')"
									@input="errors.clear('socials.twitter')"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="Linkedin url"
							:inline="true"
							:error="errors.get('socials.linkedin')"
						>
							<template #field>
								<VInput
									v-model="form.socials.linkedin"
									type="url"
									placeholder="https://www.linkedin.com/in/koko/"
									:error="errors.get('socials.linkedin')"
									@input="errors.clear('socials.linkedin')"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="Facebook url"
							:inline="true"
							:error="errors.get('socials.facebook')"
						>
							<template #field>
								<VInput
									v-model="form.socials.facebook"
									type="url"
									placeholder="https://www.facebook.com/acmecorp"
									:error="errors.get('socials.facebook')"
									@input="errors.clear('socials.facebook')"
								/>
							</template>
						</VDefaultField>
						<VDefaultField
							label="Instagram url"
							:inline="true"
							:error="errors.get('socials.instagram')"
						>
							<template #field>
								<VInput
									v-model="form.socials.instagram"
									type="url"
									placeholder="https://www.instagram.com/acmecorp"
									:error="errors.get('socials.instagram')"
									@input="errors.clear('socials.instagram')"
								/>
							</template>
						</VDefaultField>
						<!--                    <VLink-->
						<!--                        size="small"-->
						<!--                        align="right"-->
						<!--                        @click="onSubmit"-->
						<!--                    >-->
						<!--                        + add more social profiles-->
						<!--                    </VLink>-->
					</div>
					<div class="logo">
						&nbsp;
					</div>
				</div>
				<div class="organisation-save__btn">
					<VButton color="primary" classes="save-btn" type="submit">
						{{
							organisation
								? 'Save changes'
								: 'Create organisation'
						}}
					</VButton>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import { mapState } from 'vuex';

import {
	UPDATE_ORGANISATION,
	CREATE_ORGANISATION,
	FETCH_MY_ORGANISATIONS
} from '@/store/actions.type';

import { SET_CURRENT_ORGANISATION } from '@/store/mutations.type';

import Errors from '@/helpers/Errors';
import GoogleAutocomplete from './GoogleAutocomplete'
import {FETCH_COUNTRIES} from '../../../store/actions.type';

export default {
	name: 'OrganisationForm',
	computed: {
		...mapState({
			organisation: state => state.organisation.organisation,
			countries: state => state.app.countries
		})
	},
	components: {
		GoogleAutocomplete
	},
	data() {
		return {
			form: {
				name: '',
				email: '',
				website: '',
				location: '',
				address: '',
				lat: '',
				lon: '',
				overview: '',
				socials: {
					twitter: '',
					linkedin: '',
					facebook: '',
					instagram: ''
				},
				profilePhoto: {},
				initialPhoto: null,
				specialisms: []
			},
			errors: new Errors()
		};
	},
	watch: {
		'organisation.id': {
			handler: 'fillForm',
			immediate: true
		}
	},
	mounted() {
		this.$store.dispatch(FETCH_COUNTRIES, {
			global: 1
		})
	},
	methods: {
		async submit() {
			try {
				if (this.organisation) {
					await this.$store.dispatch(UPDATE_ORGANISATION, {
						slug: this.organisation.slug,
						form: this.cleanForm()
					});
					this.$toastr.s(
						'Success',
						'Organisation successfully updated.'
					);
					return;
				}

				const { data } = await this.$store.dispatch(
					CREATE_ORGANISATION,
					this.cleanForm()
				);

				this.$store.commit(SET_CURRENT_ORGANISATION, data.data);
				this.$store.dispatch(FETCH_MY_ORGANISATIONS);
			} catch (errors) {
				if (errors.response.status === 422) {
					this.errors.record(errors.response.data.errors);
				}
			}
		},
		cleanForm() {
			return {
				...this.form,
				...{
					profilePhoto: this.form.profilePhoto.generateDataUrl(),
					location: this.form.location.id,
					specialisms: this.form.specialisms.map(
						specialism => specialism.id
					)
				}
			};
		},
		fillForm() {
			if (!this.organisation) return;

			for (const key in this.form) {
				this.form[key] = this.organisation[key];
			}

			const {
				country,
				image,
				address,
				coordinates: [lat, lon]
			} = this.organisation;

			this.form.lat = lat;
			this.form.lon = lon;
			this.form.location = country;
			this.form.address = address;
			this.form.initialPhoto = image ? image : '';
		},
		getAddressData: function (addressData, placeResultData, id) {
			let country = this.countries.find(item => item.name === addressData.country);

			if (country) this.form.location = country;
			this.form.address = placeResultData.formatted_address;

			console.log(this.countries);

			this.form.lat = addressData.latitude;
			this.form.lon = addressData.longitude;
		}
	}
};
</script>

<style scoped>
/deep/ .croppa--has-target > canvas {
	border-radius: 0 !important;
}

.croppa-container {
	border-radius: 0 !important;
}
</style>
