<template>
	<Tab :limiter="false" key="organisation">
		<template #title v-if="organisation">
			<div class="org-section__partners__slider">
				<div class="org-section__partners__slider__title">
					{{ organisation.name }} profile
				</div>
				<OrganisationSlider v-if="organisations.length > 1" />
			</div>
		</template>
		<template #content v-if="organisation">
			<div class="tab-section__content__main tab-section__content__main--ewg">
				<div
					class="tab-section__content__card tab-section__content__card--visuals"
				>
					<div class="organisation-profile">
						<div class="organisation-profile__row">
							<div class="details">
								<div class="organisation-profile__row__item">
									<VText weight="bold" size="small" classes="title">
										Organisation
									</VText>
									<VText size="small" classes="description">
										{{ organisation.name }}
									</VText>
								</div>
								<div
									key="email"
									class="organisation-profile__row__item"
									v-if="organisation.email"
								>
									<VText weight="bold" size="small" classes="title">
										Org email
									</VText>
									<VLink
										size="small"
										classes="email ml-0"
										:href="`mailto:${organisation.email}`"
									>
										{{ organisation.email }}
									</VLink>
								</div>
								<div
									key="website"
									class="organisation-profile__row__item"
									v-if="organisation.website"
								>
									<VText weight="bold" size="small" classes="title">
										Website
									</VText>
									<VLink
										size="small"
										target="_blank"
										classes="ml-0"
										:href="organisation.website"
									>
										{{ organisation.website }}
									</VLink>
								</div>
								<div
									key="country"
									class="organisation-profile__row__item"
									v-if="organisation.country"
								>
									<VText weight="bold" size="small" classes="title">
										Location
									</VText>
									<VText size="small" classes="description">
										{{ organisation.country.name }}
									</VText>
								</div>
								<div
									key="address"
									class="organisation-profile__row__item"
									v-if="organisation.address"
								>
									<VText weight="bold" size="small" classes="title">
										Address
									</VText>
									<VText
										size="small"
										classes="item__label"
										v-html="organisation.address"
									/>
								</div>
							</div>
							<div class="logo-img">
								<img :src="organisation.image" alt="" style="width: 100%" />
							</div>
						</div>

						<div class="organisation-profile__row">
							<div class="organisation-profile__row__item">
								<VText weight="bold" size="small" classes="title">
									Overview
								</VText>
								<VText size="small" v-html="organisation.overview"></VText>
							</div>
						</div>
						<div
							class="organisation-profile__row organisation-profile__row--social"
						>
							<div class="organisation-profile__row__item">
								<OrganisationSocials :organisation="organisation" />
							</div>
							<div class="organisation-profile__row__item">
								<VText
									size="small"
									weight="bold"
									key="specialisms"
									classes="page-section__card__details__profile__title"
									v-if="organisation.specialisms.length"
								>
									Specialisms
								</VText>
								<div class="page-section__list">
									<div
										v-for="(specialism, index) in organisation.specialisms"
										:key="`specialism-${index}`"
										class="page-section__list__item"
									>
										<div>
											<img
												src="@/assets/imgs/icons/icon-arrow-point.svg"
												alt=""
											/>
										</div>
										<VText weight="regular">
											{{ specialism.name }}
										</VText>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<OrganisationMembers />
			</div>
		</template>
	</Tab>
</template>

<script>
import { mapState } from 'vuex';

import OrganisationSlider from '@/pages/AccountPage/YourOrganisation/OrganisationSlider';
import OrganisationMembers from '@/pages/AccountPage/YourOrganisation/OrganisationMembers';

export default {
	name: 'OrganisationProfile',
	components: {
		OrganisationMembers,
		OrganisationSlider
	},
	computed: {
		...mapState({
			organisation: state => state.organisation.organisation,
			organisations: state => state.organisation.organisations
		})
	}
};
</script>
