<template>
	<Tab :limiter="false" styling="width: 100%">
		<template #title>
			<div class="org-section__partners__slider">
				<div class="org-section__partners__slider__title">
					{{
						organisation
							? `${organisation.name} profile`
							: 'First you must create your organisation'
					}}
				</div>
				<OrganisationSlider v-if="organisations.length > 1" />
			</div>
		</template>
		<template #content>
			<div class="tab-section__content__main tab-section__content__main--ewg">
				<OrganisationForm />
				<OrganisationMembers v-if="organisation" />
			</div>
		</template>
	</Tab>
</template>

<script>
import { mapState } from 'vuex';

import OrganisationForm from '@/pages/AccountPage/YourOrganisation/OrganisationForm';
import OrganisationSlider from '@/pages/AccountPage/YourOrganisation/OrganisationSlider';
import OrganisationMembers from '@/pages/AccountPage/YourOrganisation/OrganisationMembers';

export default {
	name: 'ManageYourOrganisation',
	components: {
		OrganisationForm,
		OrganisationMembers,
		OrganisationSlider
	},
	computed: {
		...mapState({
			organisations: state => state.organisation.organisations,
			organisation: state => state.organisation.organisation
		})
	}
};
</script>
