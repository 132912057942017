<template>
	<div>
		<VHeading level="2" screen="mobile">
			Organisation members
		</VHeading>
		<div class="tab-section__content__card tab-section__content__card--visuals">
			<div class="organisation-members">
				<div>
					<div
						v-for="member in members"
						:key="`member-${member.id}`"
						class="organisation-members__item"
					>
						<div class="avatar">
							<img
								:src="member.profilePhoto"
								onerror="this.onerror=null; this.src='/img/default_user.8b07c9e6.jpg'"
								:alt="`${member.firstName} ${member.lastName} profile photo`"
							/>
						</div>
						<VText size="small" weight="bold">
							{{ member.firstName }} {{ member.lastName }}
							<span
								key="auth-client"
								v-if="memberType(member) === 'auth-client'"
							>
								(You, <VLink @click="removeMember(member)">Leave the organisation</VLink>)
							</span>
							<span
								key="auth-admin"
								v-else-if="memberType(member) === 'auth-admin'"
							>
								(You, Admin)
							</span>
							<span key="admin" v-else-if="memberType(member) === 'admin'">
								(Admin, <VLink @click.prevent.stop="removeMember(true)">Remove as admin</VLink>
								|
								<VLink @click.prevent.stop="removeMember(member)">Remove from organisation</VLink>)
							</span>
							<span key="default" v-else>
								(<VLink @click.prevent.stop="removeMember(member)">Remove from organisation</VLink>)
							</span>
						</VText>
					</div>
				</div>
				<div 
                    class="organisation-members__btn"
					v-if="this.organisation.role === 'admin'"
                >
					<VButton
						color="primary"
						classes="save-btn"
						@click.stop="
							$store.commit('setActiveModal', 'InviteTeamMembersModal')
						"
					>
						+ Invite more colleagues
					</VButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { REMOVE_MEMBER, FETCH_MEMBERS } from '@/store/actions.type';

export default {
	computed: {
		...mapGetters(['currentUser']),
		...mapState({
			organisation: state => state.organisation.organisation,
			members: state => state.organisation.members
		})
	},
	created() {
		this.$store.dispatch(FETCH_MEMBERS);
	},
	methods: {
		memberType(member) {
			if (
				this.organisation.role !== 'admin' &&
				this.currentUser.id === member.id
			) {
				return 'auth-client';
			}

			if (
				this.organisation.role === 'admin' &&
				this.currentUser.id === member.id
			) {
				return 'auth-admin';
			}

			if (
				this.organisation.role === 'admin' &&
				member.role === 'admin' &&
				this.currentUser.id !== member.id
			) {
				return 'admin';
			}

			return 'client';
		},
		async removeMember(member, asAdmin = false) {
			const willDelete = await swal({
				title: 'Are you sure?',
				text:
					'Once deleted, you will not be able to recover this imaginary file!',
				buttons: true,
				dangerMode: true
			});

			if (!willDelete) return;

			try {
				await this.$store.dispatch(REMOVE_MEMBER, {
					organisation: this.organisation.slug,
					member: member.id,
					asAdmin: asAdmin
				});

				this.$store.dispatch(FETCH_MEMBERS);
				this.$toastr.s('Success', 'Successfully removed user');
			} catch (e) {
				swal('Error while deleting', 'User can not be removed', 'error');
			}
		}
	}
};
</script>
