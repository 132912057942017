<template>
	<component :is="page" :organisation="organisation" />
</template>

<script>
import { mapState } from 'vuex';
import {
	FETCH_MY_ORGANISATIONS,
	FETCH_MY_ORGANISATION
} from '@/store/actions.type';

import OrganisationProfile from '@/pages/AccountPage/YourOrganisation/OrganisationProfile';
import ManageYourOrganisation from '@/pages/AccountPage/YourOrganisation/ManageYourOrganisation';

export default {
	name: 'YourOrganisation',
	components: {
		ManageYourOrganisation,
		OrganisationProfile
	},
	computed: {
		...mapState({
			organisation: state => state.organisation.organisation,
			organisations: state => state.organisation.organisations
		}),
		page() {
			if (!this.organisation) {
				return 'ManageYourOrganisation';
			}

			const isAdmin = this.organisation && this.organisation.role === 'admin';
			return isAdmin ? 'ManageYourOrganisation' : 'OrganisationProfile';
		}
	},
	async created() {
		await this.$store.dispatch(FETCH_MY_ORGANISATIONS);

		if (this.organisations.length) {
			this.$store.dispatch(FETCH_MY_ORGANISATION, this.organisations[0]);
		}
	}
};
</script>
